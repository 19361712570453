//
// Ckeditor Editor
//
// Overwrite/Extend styles
// --------------------------------------------------

.cke_chrome {
    border-color: $body-bg-dark !important;
}

.cke_top {
    border-bottom-color: $body-bg-dark !important;
    background: $body-bg-light !important;
}

.cke_bottom {
    border-top-color: $body-bg-dark !important;
    background: $body-bg-light !important;
}