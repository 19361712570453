//
// Transitions
// --------------------------------------------------

.fade {
    transition: opacity .15s ease-out;

    &.fade-up,
    &.fade-right,
    &.fade-left {
        transition: opacity .15s ease-out, transform .15s ease-out;
    }

    &.fade-up {
        transform: translateY(50px);

        &.show {
            transform: translateY(0);
        }
    }

    &.fade-right {
        transform: translateX(-50px);

        &.show {
            transform: translateX(0);
        }
    }

    &.fade-left {
        transform: translateX(50px);

        &.show {
            transform: translateX(0);
        }
    }
}
