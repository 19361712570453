//
// Custom Main
//
// Add your own styles or override existing ones
//
// This file is included last, so you have access
// to all Bootstrap and Dashmix functions/mixins/styles etc
// --------------------------------------------------

// If you have many custom styles, you can create and import additional files
// For example you can create _component1.scss and include it as follows:
// @import 'component1';
.fly-mode-resize-drag-desktop,
.fly-mode-resize-drag-mobile {
    background-color: #29e;
    color: white;
    font-size: 20px;
    font-family: sans-serif;
    border-radius: 8px;
    padding: 20px;
    touch-action: none;
    user-select: none;

    width: 120px;

    /* This makes things *much* easier */
    box-sizing: border-box;
}
