//
// Reboot
// --------------------------------------------------

// Document
html {
    height: 100%;
    font-size: $font-size-root;
}

// Body
body {
    height: 100%;
    @if $enable-text-optimize-legibility {
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

b,
strong {
    font-weight: $font-weight-bold;
}

// Links
a {
    transition: color .12s ease-out;

    @include media-breakpoint-up(sm) {
        &.link-fx {
            position: relative;

            &::before {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                content: '';
                background-color: $link-color;
                visibility: hidden;
                transform: scaleX(0);
                transform-origin: 0 50%;
                transition: transform .2s ease-out;
            }
        }
    }

    @include hover {
        &.link-fx::before {
            visibility: visible;
            transform: scale(1);
        }
    }

    &:focus {
        outline-style: none !important;
    }
}

// Paragraphs
p {
    line-height: $paragraph-line-height;
    margin-bottom: $space-base;
}

.story p,
p.story {
    line-height: $paragraph-line-height;
    font-size: $font-size-lg;
    color: gray("700");

    @include media-breakpoint-up(sm) {
        line-height: $paragraph-line-height-lg;
    }
}

.story {
    h2,
    h3,
    h4 {
        margin-top: 3rem;
    }
}

// Forms
label {
    font-weight: 600;
}
