//
// Modal
// --------------------------------------------------

// Top position
.modal-dialog.modal-dialog-top {
    margin-top: 0 !important;
}

// Modal Transitions
.modal {
    &.fade .modal-dialog {
        transition-duration: .4s;

        &.modal-dialog-popin {
            transform: scale(1.25, 1.25);
        }

        &.modal-dialog-popout {
            transform: scale(.75, .75);
        }

        &.modal-dialog-slideup {
            transform: translate(0, 30%);
        }

        &.modal-dialog-slideright {
            transform: translate(-30%, 0);
        }

        &.modal-dialog-slideleft {
            transform: translate(30%, 0);
        }

        &.modal-dialog-fromright {
            transform: translateX(30%) rotate(10deg) scale(.75);
        }

        &.modal-dialog-fromleft {
            transform: translateX(-30%) rotate(-10deg) scale(.75);
        }
    }

    &.show .modal-dialog {
        &.modal-dialog-popin,
        &.modal-dialog-popout {
            transform: scale(1, 1);
        }

        &.modal-dialog-slideup,
        &.modal-dialog-slideright,
        &.modal-dialog-slideleft {
            transform: translate(0, 0);
        }

        &.modal-dialog-fromright,
        &.modal-dialog-fromleft {
            transform: translateX(0) rotate(0) scale(1);
        }
    }
}
