//
// Text emphasis
// --------------------------------------------------

@mixin text-emphasis-variant($parent, $color) {
    #{$parent} {
        color: $color !important;
    }

    a#{$parent} {
        &.link-fx::before {
            background-color: $color;
        }

        @include hover-focus {
            color: darken($color, 20%) !important;
        }
    }
}
