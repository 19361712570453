//
// Buttons
// --------------------------------------------------

// Hero variant
@mixin button-hero-variant($background, $hover-background: lighten($background, 10%),  $active-background: darken($background, 15%)) {
    $btn-hero-box-shadow: 0 .125rem .75rem rgba($active-background, .25);
    $btn-hero-box-shadow-hover: 0 .375rem .75rem rgba($active-background, .4);

    color: color-yiq($background);
    text-transform: uppercase;
    letter-spacing: .0625rem;
    font-weight: 700;
    @include button-size($btn-hero-padding-y, $btn-hero-padding-x, $font-size-sm, $btn-line-height, $btn-hero-border-radius);
    @include gradient-bg($background);
    border: none;
    box-shadow: $btn-hero-box-shadow;
    transition: $btn-transition, transform .12s ease-out;

    @include hover {
        color: color-yiq($hover-background);
        @include gradient-bg($hover-background);
        box-shadow: $btn-hero-box-shadow-hover;
        transform: translateY(-1px);
    }

    &:focus,
    &.focus {
        color: color-yiq($hover-background);
        @include gradient-bg($hover-background);
        box-shadow: $btn-hero-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        color: color-yiq($background);
        background-color: $background;
        box-shadow: $btn-hero-box-shadow;
        transform: translateY(0);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        @if $enable-gradients {
            background-image: none; // Remove the gradient for the pressed/active state
        }
        box-shadow: $btn-hero-box-shadow;
        transform: translateY(0);

        &:focus {
            box-shadow: $btn-hero-box-shadow;
        }
    }
}

// Used for buttons that adapt to light/dark header and sidebar variations
@mixin button-dual-variant($color, $background) {
    color: $color;
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:focus,
    &.focus {
        color: $color;
        background-color: $background;
        border-color: $background;
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        background-color: transparent;
        border-color: transparent;
    }

    &:active,
    &.active {
        color: $color;
        background-color: transparent;
        border-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $color;
        background-color: $background;
        border-color: $background;
    }
}
